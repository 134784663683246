import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import { BsPlusLg } from "react-icons/bs";
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';

const style = {
  position: "fixed",
  top: "0",
  right: "0",
  width: "60%",
  maxWidth: "500px",
  maxHeight: "100%",
  bgcolor: "white",
  boxShadow: 24,
  paddingLeft:"5%",
  paddingRight:"3%",
  overflow: "auto",
  zIndex: 9999,

  "&::-webkit-scrollbar": {
    //Beautifying scrollbar
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
};

interface AddFlightModalProps {
  open: boolean;
  onClose: () => void;
}

function AddFlightModal({ open, onClose }: AddFlightModalProps) {
  return (
      <Modal open={open} onClose={onClose}>
        <Box sx={style}>
          <form action="">
          <div>
            <div className="clear_icon">
              <ClearIcon  style={{marginTop:"2%"}} onClick={onClose} />
            </div>
            <h2 className="heading2">Add New Flight</h2>
            <p className="heading4 secondary" style={{ fontWeight: "600" }}>
              Flight Details
            </p>
            <div
              className="f-flex"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <label className="heading5" style={{ marginBottom: "5px" }}>
                Aircraft
              </label>
              <button
                className="add_flight_btn"
                style={{ marginBottom: "15px" }}
              >
                <BsPlusLg /> Add Aircraft
              </button>
            </div>
            <div className="add_group" style={{ width: "90%" }}>
              <input type="text" placeholder="select" className="add_input" />
            </div>
            <div className="f-flex">
              <label
                className="heading5"
                style={{ marginBottom: "5px", display: "flex", flexGrow: "1" }}
              >
                Flight Number
              </label>
              <label
                className="heading5"
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexGrow: "1",
                  marginRight: "10%",
                }}
              >
                Date
              </label>
            </div>
            <div className="f-flex">
              <div
                className="add_group"
                style={{ display: "flex", flexGrow: "1" }}
              >
                <input
                  type="text"
                  placeholder="UTF3235"
                  className="add_input"
                />
              </div>
              <div
                className="add_group"
                style={{ display: "flex", flexGrow: "1" }}
              >
                <input type="date" placeholder="Select" className="add_input" />
              </div>
            </div>
            <div className="f-flex">
              <label
                className="heading5"
                style={{ marginBottom: "5px", display: "flex", flexGrow: "1" }}
              >
                From
              </label>
              <label
                className="heading5"
                style={{ marginBottom: "5px", display: "flex", flexGrow: "1" }}
              >
                To
              </label>
            </div>
            <div className="f-flex">
              <div
                className="add_group"
                style={{ display: "flex", flexGrow: "1" }}
              >
                <input type="text" placeholder="UK" className="add_input" />
              </div>
              <div
                className="add_group"
                style={{ display: "flex", flexGrow: "1" }}
              >
                <input type="text" placeholder="USA" className="add_input" />
              </div>
            </div>
            <div className="f-flex">
              <label
                className="heading5"
                style={{ marginBottom: "5px", display: "flex", flexGrow: "1" }}
              >
                Out (UTC)
              </label>
              <label
                className="heading5"
                style={{ marginBottom: "5px", display: "flex", flexGrow: "1" }}
              >
                In (UTC)
              </label>
            </div>
            <div className="f-flex">
              <div
                className="add_group"
                style={{ display: "flex", flexGrow: "1" }}
              >
                <input type="text" placeholder="+9:00" className="add_input" />
              </div>
              <div
                className="add_group"
                style={{ display: "flex", flexGrow: "1" }}
              >
                <input type="text" placeholder="+12:00" className="add_input" />
              </div>
            </div>
            <p className="heading4 secondary" style={{ fontWeight: "600" }}>
              TIME
            </p>
            <label
              className="heading5"
              style={{ marginBottom: "5px", display: "flex", flexGrow: "1" }}
            >
              Total Time
            </label>
            <div
              className="add_group"
              style={{ width: "90%", display: "flex", flexGrow: "1" }}
            >
              <input type="text" placeholder="3 hours" className="add_input" />
            </div>

            <div style={{ display: "flex" }}>
              <label
                className="heading5"
                style={{ marginBottom: "5px", display: "flex", flexGrow: "1" }}
              >
                Takeoffs
              </label>
              <label
                className="heading5"
                style={{ marginBottom: "5px", display: "flex", flexGrow: "1" }}
              >
                Landings
              </label>
            </div>
            <div className="f-flex" style={{ gap: "8px" }}>
              <div
                className="add_group"
                style={{ display: "flex", flexGrow: "1" }}
              >
                <input type="text" placeholder="Day" className="add_input" />
              </div>
              <div
                className="add_group"
                style={{ display: "flex", flexGrow: "1" }}
              >
                <input type="text" placeholder="Night" className="add_input" />
              </div>

              <div className="f-flex" style={{ gap: "8px" }}>
                <div
                  className="add_group"
                  style={{ display: "flex", flexGrow: "1" }}
                >
                  <input type="text" placeholder="Day" className="add_input" />
                </div>
                <div
                  className="add_group"
                  style={{ display: "flex", flexGrow: "1" }}
                >
                  <input
                    type="text"
                    placeholder="Night"
                    className="add_input"
                  />
                </div>
              </div>
            </div>

            <div className="f-flex" style={{ display: "flex", flexGrow: "1" }}>
              <label
                className="heading5"
                style={{ marginBottom: "5px", display: "flex", flexGrow: "1" }}
              >
                Out (UTC)
              </label>
              <label
                className="heading5"
                style={{ marginBottom: "5px", display: "flex", flexGrow: "1" }}
              >
                In (UTC)
              </label>
            </div>
            <div className="f-flex">
              <div
                className="add_group"
                style={{ display: "flex", flexGrow: "1" }}
              >
                <input type="text" placeholder="+9:00" className="add_input" />
              </div>
              <div
                className="add_group"
                style={{ display: "flex", flexGrow: "1" }}
              >
                <input type="text" placeholder="+12:00" className="add_input" />
              </div>
            </div>
            <p className="heading4 secondary" style={{ fontWeight: "600" }}>
              Duty
            </p>
            <div className="f-flex" style={{ display: "flex", flexGrow: "1" }}>
              <label
                className="heading5"
                style={{ marginBottom: "5px", display: "flex", flexGrow: "1" }}
              >
                Out (UTC)
              </label>
              <label
                className="heading5"
                style={{ marginBottom: "5px", display: "flex", flexGrow: "1" }}
              >
                In (UTC)
              </label>
            </div>
            <div className="f-flex">
              <div
                className="add_group"
                style={{ display: "flex", flexGrow: "1" }}
              >
                <input type="text" placeholder="+9:00" className="add_input" />
              </div>
              <div
                className="add_group"
                style={{ display: "flex", flexGrow: "1" }}
              >
                <input type="text" placeholder="+12:00" className="add_input" />
              </div>
            </div>
           
            <div className="add_span" style={{ flexGrow: "2" }}>
                <label className="heading4 secondary" style={{ fontWeight: "600" }}>
                  Crew
                </label>
               
              </div>
              <div style={{ background: "#F8FAFB", display:"flex", justifyContent:"space-between", padding:"3%", borderRadius:"10px", marginBottom:"5%" }}>
                    <span>
                    Name
                    </span>
                    <div>
                  <input
                style={{
                  padding: '3%',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  fontSize: '14px',
                  width: '100%',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
                }}
                    type="text"
                    id="textInput" 
                    placeholder="Enter text..."  />
                    </div>
                  <DeleteOutlineSharpIcon style={{color:"red"}}/>
                </div>
                <div>
              <button className="add_btn" style={{ float: "right" }}>
                <span>Preview Flight</span>
              </button>
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
          </form>
        </Box>
      </Modal>
  );
}

export default AddFlightModal;
