import axios from "axios";
import HP from "../../host";

export async function addNewFlight(body:object) {
  return await axios.post(`${HP.request}://${HP.host}/flight/newflight`, body);
}

export async function getAircraftAeroApi(id:string){
   return await axios.get(`${HP.request}://${HP.host}/aeroapiaircrafthistory/getaircrafthistory/${id}`);
}

export async function addNewAircraft(body:object) {
    return await axios.post(`${HP.request}://${HP.host}/aircraft/newAircraft`, body);
  }
