import "./Header.css";
import logo from "../../assets/images/logo_one.svg";
import logbook from "../../assets/images/Logbook.svg";
import reports from "../../assets/images/Report.svg";
import training from "../../assets/images/Training.svg";
import profile from "../../assets/images/Profile.svg";
import info from "../../assets/images/Info.svg";
import bell from "../../assets/images/Bell.svg";
import avatar from "../../assets/images/Avatar.svg";
import line from "../../assets/images/Line.svg";
import {IoMdArrowDropdown} from "react-icons/io"
function Header() {
  return (
    <div>
    <nav className="header">
      <img src={logo} className="logo" alt="Pilot Logbook Logo" />
      <div className="icons_box">
        <img src={logbook} alt="Logbook Logo" />
        <p className="heading4" style={{ paddingRight: "6%" }}>
          Logbook
        </p>
        <img src={reports} alt="Reports Logo" />
        <p className="heading4" style={{ paddingRight: "6%" }}>
          Reports
        </p>
        <img src={training} alt="Training Logo" />
        <p className="heading4" style={{ paddingRight: "6%" }}>
          Training
        </p>
        <img src={profile} alt="Profile Logo" />
        <p className="heading4" style={{ paddingRight: "6%" }}>
          Profile
        </p>
      </div>
        <div className="icons_left">
        <img src={info} style={{paddingLeft:'25%'}} alt="Info Logo" />
        <img src={bell} style={{paddingLeft:'10px'}} alt="Bell Logo" />
        <img src={line} style={{paddingLeft:'2%',paddingRight:'2%'}} alt="Line" />
        <img src={avatar} alt="Avatar" />
        <p className="heading4" style={{width:"240px"}} >
          John Smith
        </p>
        <IoMdArrowDropdown style={{fontSize:"28px"}} />
        </div> 
    </nav>
    </div>
  );
}

export default Header;
