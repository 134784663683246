// import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
// import Home from "./Dashboard/Home";
// import Flights from "./Dashboard/Flight";
// import { CircularProgress } from "@mui/material";
function Layout() {
  return (
    <>
      <Header />
      <Sidebar />
      {/* <Home/> */}
      {/* <Flights/> */}
      {/* <div>
          <Outlet />
        </div> */}
    </>
  );
}

export default Layout;
