import "./viewFlight.css";
import React from "react";
import { BsPlusLg } from "react-icons/bs";
import { TbEdit } from "react-icons/tb";
import { HiUserCircle } from "react-icons/hi";
function ViewFlight() {
  return (
    <div className="dashboard">
      <section
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="dashboard_heading">Flight: Abc12345</h4>
        <h6 className="dashboard_subheading">
          Dashboard {">"} Flight: Abc12345
        </h6>
      </section>
      <section className="btn_container">
        <button className="add_flight_btn">
          <TbEdit /> Edit Flight
        </button>
        <section style={{ display: "flex", gap: "15px" }}>
          <button className="add_flight_btn">
            <BsPlusLg /> Add Flight
          </button>
          <button className="add_aircraft_btn">
            <BsPlusLg /> Add Aircraft
          </button>
        </section>
      </section>
      <div
        className="div_container"
        style={{ marginTop: "2%", padding: "30px" }}
      >
        <div>
        <div style={{ display: "flex", gap: "2%" }}>
          <section className="flight_sections">
            <h3 className="flight_heading secondary">FLIGHT DETAILS</h3>
            <div className="flight_details">
              <section>
                <p className="flight_subheading">Flight Number</p>
                <p className="flight_subheading">Date</p>
                <p className="flight_subheading">Aircraft</p>
                <p className="flight_subheading">In (UTC)</p>
                <p className="flight_subheading">Out (URC)</p>
              </section>
              <section>
                <p className="flight_subheading">ABC12345</p>
                <p className="flight_subheading">20 Aug, 2023</p>
                <p className="flight_subheading">ATU-0000</p>
                <p className="flight_subheading">08:00 PM</p>
                <p className="flight_subheading">12:00 AM</p>
              </section>
            </div>
          </section>
          <section className="flight_sections">
            <h3 className="flight_heading secondary">TIME</h3>
            <div className="flight_details">
              <section>
                <p className="flight_subheading">Total Time</p>
                <p className="flight_subheading">Takeoff (Day)</p>
                <p className="flight_subheading">Takeoff (Night)</p>
                <p className="flight_subheading">Landing (Day)</p>
                <p className="flight_subheading">Landing (Night)</p>
              </section>
              <section>
                <p className="flight_subheading">14:00</p>
                <p className="flight_subheading">20</p>
                <p className="flight_subheading">05</p>
                <p className="flight_subheading">02</p>
                <p className="flight_subheading">03</p>
              </section>
            </div>
          </section>
        </div>
       
        <div style={{ display: "flex", gap: "2%" }}>
          <section className="flight_sections" style={{ marginTop: "2%" }}>
            <h3 className="flight_heading secondary">Duty</h3>
            <div className="flight_details">
              <section>
                <p className="flight_subheading">On Duty (UTC)</p>
                <p className="flight_subheading">Off Duty (UTC)</p>
              </section>
              <section>
                <p className="flight_subheading">02:00</p>
                <p className="flight_subheading">14:00</p>
              </section>
            </div>
          </section>
          <section className="flight_sections" style={{ marginTop: "2%" }}>
            <h3 className="flight_heading secondary">OPERATIONS</h3>
            <div className="flight_details">
              <section>
                <p className="flight_subheading">APR</p>
                <p className="flight_subheading">APR</p>
              </section>
              <section>
                <p className="flight_subheading">ABC</p>
                <p className="flight_subheading">ABC</p>
              </section>
            </div>
          </section>
        </div>
        </div>
        {/* <section>
          <p></p>
        </section> */}
        <section className="flight_sections" style={{ marginTop: "2%" }}>
          <h3 className="flight_heading secondary">CREW</h3>
          <section style={{ display: "flex", columnGap: "5%" }}>
            <div className="crew_div">
              <HiUserCircle
                className="secondary"
                style={{ fontSize: "30px" }}
              />
              <p className="flight_subheading">John Smith</p>
            </div>
            <div className="crew_div">
              <HiUserCircle
                className="secondary"
                style={{ fontSize: "30px" }}
              />
              <p className="flight_subheading">John Smith</p>
            </div>
            <div className="crew_div">
              <HiUserCircle
                className="secondary"
                style={{ fontSize: "30px" }}
              />
              <p className="flight_subheading">John Smith</p>
            </div>
          </section>

          <section style={{ display: "flex", columnGap: "5%" }}>
            <div className="crew_div">
              <HiUserCircle
                className="secondary"
                style={{ fontSize: "30px" }}
              />
              <p className="flight_subheading">John Smith</p>
            </div>
            <div className="crew_div">
              <HiUserCircle
                className="secondary"
                style={{ fontSize: "30px" }}
              />
              <p className="flight_subheading">John Smith</p>
            </div>
          </section>
        </section>

        <section className="flight_sections" style={{ marginTop: "2%" }}>
          <h3 className="flight_heading secondary">REMARKS</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </section>
      </div>
    </div>
  );
}

export default  ViewFlight;
