import "./Login.css";
import header from "../../assets/images/logo_one.svg";
import facebookIcon from "../../assets/images/Facebook.svg";
import linkedinIcon from "../../assets/images/Linkedin.svg";
import twitterIcon from "../../assets/images/Twitter.svg";
import gmailIcon from "../../assets/images/Gmail.svg";
import footerImage from "../../assets/images/footer_img.svg";
import backgroundImage from "../../assets/images/Login .png";
function Login() {
  return (
    <div>
      <img src={header} className="login_logo" alt="Pilot Book" />
      <form>
        <h2 className="welcome heading2">Welcome Back!</h2>
        <h4 className="login_subheading heading4">
          Sign in to continue to Pilots Logbook
        </h4>
        <label className="l_email_label heading4">Email</label>
        <input
          type="text"
          placeholder="Enter Your Email"
          className="l_email_input"
          required
        />
        <label className="l_pass_label heading4">Password</label>
        <input
          type="text"
          placeholder="Enter Your Password"
          className="l_pass_input"
          required
        />
        <span className="l_span">
          <input
            type="checkbox"
            className="l_checkbox"
            id="remember_me"
            name="remember me"
          />
          <label className="text_checkbox heading4">Remember me</label>
        </span>
        <button className="login_btn">Login</button>
      </form>
      <h4 className="login_with heading4">Login in with</h4>
      <div className="l_social_icons">
        <img src={facebookIcon} className="l_facebook" alt="Facebook Icon" />
        <img src={linkedinIcon} className="l_linkedin" alt="LinkedIn Icon" />
        <img src={twitterIcon} className="l_twitter" alt="Twitter Icon" />
        <img src={gmailIcon} className="l_gmail" alt="Gmail Icon" />
      </div>
      <h4 className="signup_now heading4">
        Don't have an account ?{" "}
        <a href="/register">
          <span style={{ textDecoration: "underline", color: "#232629" }}>
            Signup now
          </span>
        </a>
      </h4>
      <h4 className="forget_pass heading4">Forgot Password</h4>
      <img src={footerImage} className="footer_img" alt="LandScape" />
      <h4 className="copyright heading4">© 2023 Pilots Logbook</h4>
      <img src={backgroundImage} className="login_bg" alt="Login Background" />
      <h4 className="login_bg_heading">
        The most complete digital pilot logbook
      </h4>
      <h4 className="login_bg_subheading">
        Pilots Logbook makes your life easier through its super fast flight
        logging, detailed analysis of your flight time with the Analyze view,
        the powerful InSight technology that monitors all your upcoming flights
        and warns you of any rest / duty / currency issues, with all your
        irreplaceable flying life backed up to your choice of iCloud, Dropbox,
        or both!
      </h4>
    </div>
  );
}

export default Login;
