import plane from "../../assets/images/Plane.svg"
import map from "../../assets/images/map.svg"
import "./Home.css"
import {BiImport} from "react-icons/bi"
function Home() {
    return (
        <div className="dashboard">
            <section style={{ display: "flex",alignItems:"center",justifyContent:"space-between" }}>
                <h4 className="dashboard_heading">Dashboard</h4>
                <h6 className="dashboard_subheading">Dashboard {">"} Home</h6>
            </section>
         <section
                style={{
                    background: "#DDEBFC",
                    marginRight: "10%",
                    paddingRight: "30%",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    borderRadius: "6px",
                    width:"55vw"
                }}
            >
                <h4
                    className="heading3"
                    style={{ fontWeight: "600", paddingLeft: "27px" }}
                >
                    Welcome back, John Smith{" "}
                </h4>
                <p className="heading4" style={{ paddingLeft: "27px" }}>
                    Manage your flights and see your currency and totals. You can also
                    upload or download your logbook, and get a printer-optimized view!
                </p>
            </section>
            <div style={{display:"flex",gap:"6%"}}>
                <section style={{marginTop:"5px"}}>
                    <ul className="btn_list">
                        <li
                            className="heading4"
                            style={{ fontWeight: "600", marginTop: "5%" }}
                        >
                            {" "}
                            Quick Actions
                        </li>
                        <li style={{ marginTop: "12%" }}>
                            <button className="flight_btn">+ Add Flight</button>
                        </li>
                        <li style={{ marginTop: "12%" }}>
                            <button className="aircraft_btn">+ Add Aircraft</button>
                        </li>
                        <li style={{ marginTop: "12%" }}>
                            <button className="airport_btn">+ Add Airport</button>
                        </li>
                        <li style={{ marginTop: "12%", marginBottom: "20%" }}>
                            <button className="import_btn"><BiImport/> Import/Export</button>
                        </li>
                    </ul>
                </section>
                <div>
                    <div style={{ display: "flex", gap: "5%" }}>
                        <section className="plane_section">
                            <img src={plane} style={{ width: "50px" }} alt="Plane Logo" />
                            <ul style={{ listStyle: "none", paddingLeft: "5%" }}>
                                <li className="heading5 text_color2">Total Hours</li>
                                <li className="heading3 text">452</li>
                                <li className="heading5 secondary">View Details</li>
                            </ul>
                        </section>
                        <section className="plane_section">
                            <img src={plane} style={{ width: "50px" }} alt="Plane Logo" />
                            <ul style={{ listStyle: "none", paddingLeft: "5%" }}>
                                <li className="heading5 text_color2">Aircrafts</li>
                                <li className="heading3 text">45</li>
                                <li className="heading5 secondary">View Details</li>
                            </ul>
                        </section>
                    </div>
                    <div style={{ display: "flex", gap: "5%" }}>
                        <section className="plane_section">
                            <img src={plane} style={{ width: "50px" }} alt="Plane Logo" />
                            <ul style={{ listStyle: "none", paddingLeft: "5%" }}>
                                <li className="heading5 text_color2">Flights</li>
                                <li className="heading3 text">125</li>
                                <li className="heading5 secondary">View Details</li>
                            </ul>
                        </section>
                        <section className="plane_section">
                            <img src={plane} style={{ width: "50px" }} alt="Plane Logo" />
                            <ul style={{ listStyle: "none", paddingLeft: "5%" }}>
                                <li className="heading5 text_color2">Airports</li>
                                <li className="heading3 text">125</li>
                                <li className="heading5 secondary">View Details</li>
                            </ul>
                        </section>
                    </div>
                </div>
            </div>
            <section className="map_section">
                <h4 className="heading4" style={{fontWeight:"600",paddingLeft:"5%"}}>Visited Places</h4>
                <img src={map} alt="Map" style={{paddingLeft:"4%"}}/>
            </section>
        </div>);
}

export default Home;