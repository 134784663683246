
  
//   For Localhost //
  // const HP = {
  //  request:"http",
  //   host: "localhost:3002",
  // };

  const HP = {
    request:"http",
     host: "flightlogbooks.onrender.com",
   };
   
  
  export default HP;
  