import "./Flights.css";
import React from "react";
import {FaEllipsisH } from 'react-icons/fa';
import {BsPlusLg} from "react-icons/bs";
import {RiArrowDropUpLine} from "react-icons/ri"
import {GrView} from 'react-icons/gr'
import { TbEdit } from "react-icons/tb";
import {AiOutlineDelete} from "react-icons/ai"
import { Link } from "react-router-dom";
function Flights() {
    const allFlights = [
        {
          id:1,
          flight: 'England to Pakistan',
          tail: 'tail',
          cat: 'cat.',
          lndgs: 'lndgs.',
          x_c: '01',
          nights: '02',
          imc:"02",
          grd_sim:"12",
          dual_rcvd:"08",
          as_instr:"02",
          sic:"06",
          pic:"45",
          total:"75",
          isDropdownOpen: false
        },
        {
          id:2,
          flight: 'England to Pakistan',
          tail: 'tail',
          cat: 'cat.',
          lndgs: 'lndgs.',
          x_c: '01',
          nights: '02',
          imc:"02",
          grd_sim:"12",
          dual_rcvd:"08",
          as_instr:"02",
          sic:"06",
          pic:"45",
          total:"75",
          isDropdownOpen: false
        },
        {
          id:3,
          flight: 'England to Pakistan',
          tail: 'tail',
          cat: 'cat.',
          lndgs: 'lndgs.',
          x_c: '01',
          nights: '02',
          imc:"02",
          grd_sim:"12",
          dual_rcvd:"08",
          as_instr:"02",
          sic:"06",
          pic:"45",
          total:"75",
          isDropdownOpen: false
        },
        {
          id:4,
          flight: 'England to Pakistan',
          tail: 'tail',
          cat: 'cat.',
          lndgs: 'lndgs.',
          x_c: '01',
          nights: '02',
          imc:"02",
          grd_sim:"12",
          dual_rcvd:"08",
          as_instr:"02",
          sic:"06",
          pic:"45",
          total:"75",
          isDropdownOpen: false
        },
        {
          id:5,
          flight: 'England to Pakistan',
          tail: 'tail',
          cat: 'cat.',
          lndgs: 'lndgs.',
          x_c: '01',
          nights: '02',
          imc:"02",
          grd_sim:"12",
          dual_rcvd:"08",
          as_instr:"02",
          sic:"06",
          pic:"45",
          total:"75",
          isDropdownOpen: false
        },
        {
          id:6,
          flight: 'England to Pakistan',
          tail: 'tail',
          cat: 'cat.',
          lndgs: 'lndgs.',
          x_c: '01',
          nights: '02',
          imc:"02",
          grd_sim:"12",
          dual_rcvd:"08",
          as_instr:"02",
          sic:"06",
          pic:"45",
          total:"75",
          isDropdownOpen: false
        },
        {
          id:7,
          flight: 'England to Pakistan',
          tail: 'tail',
          cat: 'cat.',
          lndgs: 'lndgs.',
          x_c: '01',
          nights: '02',
          imc:"02",
          grd_sim:"12",
          dual_rcvd:"08",
          as_instr:"02",
          sic:"06",
          pic:"45",
          total:"75",
          isDropdownOpen: false
        },
        {
          id:8,
          flight: 'England to Pakistan',
          tail: 'tail',
          cat: 'cat.',
          lndgs: 'lndgs.',
          x_c: '01',
          nights: '02',
          imc:"02",
          grd_sim:"12",
          dual_rcvd:"08",
          as_instr:"02",
          sic:"06",
          pic:"45",
          total:"75",
          isDropdownOpen: false
        },
        {
          id:9,
          flight: 'England to Pakistan',
          tail: 'tail',
          cat: 'cat.',
          lndgs: 'lndgs.',
          x_c: '01',
          nights: '02',
          imc:"02",
          grd_sim:"12",
          dual_rcvd:"08",
          as_instr:"02",
          sic:"06",
          pic:"45",
          total:"75",
          isDropdownOpen: false
        }
      ];
      
      const [selectedRow, setSelectedRow] = React.useState(null);

      const handleDotClick = (index:any) => {
        setSelectedRow(index === selectedRow ? null : index);
      };
  return (
    <div className="dashboard">
      <section style={{ display: "flex",alignItems:"center",justifyContent:"space-between" }}>
        <h4 className="dashboard_heading">Flights</h4>
        <h6 className="dashboard_subheading">Dashboard {">"} Flights</h6>
      </section>
      <section className="allflights" >
        <h4 className="heading4" style={{fontWeight:"500"}}>All Flights</h4>
        <section style={{display:"flex",gap:"15px"}}>
        <button className="filter_btn">Filter <RiArrowDropUpLine/></button>
        <button className="filter_btn">Filter <RiArrowDropUpLine/></button>
        <button className="filter_btn">Filter <RiArrowDropUpLine/></button>
        <button className="add_flight_btn"><BsPlusLg/> Add Flight</button>
        <button className="add_aircraft_btn"><BsPlusLg/> Add Aircraft</button>
        </section>
      </section>
            <div className="div_container">
                <div className='today_fleets'>
                    <div className="header_th">
                        <div>Flight</div>
                        <div>Tail</div>
                        <div>Cat.</div>
                        <div>Lndgs.</div>
                        <div>X-C</div>
                        <div>Nights</div>
                        <div>IMC</div>
                        <div>Grnd. Sim.</div>
                        <div>Dual Rcvd.</div>
                        <div>As Instr.</div>
                        <div>SIC</div>
                        <div>PIC</div>
                        <div>Total</div>
                        <div>&nbsp;</div>
                    </div>
                    <div>
                    {allFlights.map((fleet, index) => (
                        <div className="header_td" key={index} style={{ backgroundColor: index % 2 === 0 ? '#EFF6FF' : 'white'}}>
                            <div>{fleet.flight}</div>
                            <div>{fleet.tail}</div>
                            <div>{fleet.cat}</div>
                            <div>{fleet.lndgs}</div>
                            <div>{fleet.x_c}</div>
                            <div>{fleet.nights}</div>
                            <div>{fleet.imc}</div>
                            <div>{fleet.grd_sim}</div>
                            <div>{fleet.dual_rcvd}</div>
                            <div>{fleet.as_instr}</div>
                            <div>{fleet.sic}</div>
                            <div>{fleet.pic}</div>
                            <div>{fleet.total}</div>
                            <div>
                                <div className="edit_menu" onClick={() => handleDotClick(index)}>
                                    <FaEllipsisH className="edit_dots"/>
                                </div> 
                                {selectedRow === index && (
                                  <ul className="edit_menu_ul" v-if="fleet.isDropdownOpen">
                                    <li><GrView/><Link to='/logbook/flights/view_flight'>  View</Link></li>
                                    <li><TbEdit/><a href="">  Edit</a></li>
                                    <li><AiOutlineDelete style={{color:"red"}}/><a href="">  Delete</a></li>
                                  </ul>
                                )}
                                
                            </div>
                        </div>
                    ))}
                    </div>
                
                </div>
                
                
            </div>
    </div>
  );
}

export default Flights;
