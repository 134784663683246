import React from 'react';
import './App.css';
// import Logo from './assets/images/logo.svg'
import Login from './pages/Login/Login';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import Register from './pages/Register/Register';
// import Header from './components/Header/Header';
import Layout from './pages/Layout';
import Home from './pages/Dashboard/Home';
import Flights from './pages/Dashboard/Flights/Flights';
import ViewFlight from './pages/Dashboard/Flights/viewFlight';
import Aircrafts from './pages/Dashboard/Aircraft/Aircrafts';
import AircraftModels from './pages/Dashboard/Aircraft/aircraftModels';
function App() {
  return (
    <div >  
      <Router>
      <Layout/>
       <Routes>
       <Route  path='/' element={<Home/>}></Route>
       <Route  path='/logbook/flights' element={<Flights/>}></Route>
       <Route path='/logbook/flights/view_flight' element={<ViewFlight/>}></Route>
       <Route path='/logbook/aircrafts' element={<Aircrafts/>}></Route>
       <Route path='/logbook/aircrafts/aircraft_models' element={<AircraftModels/>}></Route>
       <Route  path='/login' element={< Login />}></Route>
        <Route  path='/register' element={< Register />}></Route>     
                {/* <Route  path='/contact' element={< Contact />}></Route> */}
        </Routes>
        </Router>
      {/* <header className="App-header">
    <img src={Logo} alt='Pilot Fligtbook'/>
    <h2 className='heading_one'>Powered By iSubhan and Team</h2>
      </header>   */}
    </div>

    
  );
}

export default App;
