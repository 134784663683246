import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import { addNewAircraft, getAircraftAeroApi } from "../Axios/Axios";
import ClearIcon from "@mui/icons-material/Clear";
const style = {
  position: "fixed",
  top: "0",
  right: "0",
  width: "60%",
  maxWidth: "500px",
  maxHeight: "100%",
  bgcolor: "white",
  boxShadow: 24,
  paddingLeft:"5%",
  paddingRight:"2%",
  overflow: "auto",
  zIndex: 9999,

  "&::-webkit-scrollbar": {
    //Beautifying scrollbar
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
};

interface AddAircraftModalProps {
  open: boolean;
  onClose: () => void;
}

function AddAircraftModal({ open, onClose }: AddAircraftModalProps) {
  const [addAircraft, setAddAircraft] = React.useState(false);
  const [formsubmit, setFormSubmit] = React.useState(false);
  const [aircarftid, setAircarftid] = React.useState("");
  const [aircrafttype, setAircrafttype] = React.useState("");
  const [bodytype, setBodytype] = React.useState("");
  const [enginetype, setEnginetype] = React.useState("");
  const [defaultValue, setDefaultValue] = React.useState(false);
  const [favorite, setFavorite] = React.useState(false);

  //Managing All Checkboxes
  const handleDefaut = (event: any) => {
    console.log(!defaultValue);
    setDefaultValue(!defaultValue);
  };
  const handleFavorite = (event: any) => {
    setFavorite(!favorite);
    console.log(favorite);
  };

  //For Registeration Number
  const handleReg = async (e: any) => {
    // setRegNumber(e.target.value);
    if (e.target.value.length > 5) {
      const data = await getAircraftAeroApi(e.target.value);
      setAircarftid(data.data.aircraft_type);
      setAircrafttype(data.data.jsonResponse1.type);
      setBodytype(data.data.jsonResponse1.description);
      setEnginetype(data.data.jsonResponse1.engine_type);
      console.log(data.data);
      console.log(aircarftid);
    } else {
      console.log("enter 6 digits");
    }
  };
    
  //Add Aircraft
  const onSubmitForm = async (e: any) => {
    e.preventDefault();

    try {
      const body = { aircarftid, aircrafttype, bodytype, enginetype };

      const res = await addNewAircraft(body);
      console.log(res);
      setFormSubmit(true);
    } catch (error: any) {
      console.error(error.message);
    }
  };
  const handleAddAircraft = () => {
    setAddAircraft(true);
  };

  const handleAddFlight = () => {
    setAddAircraft(false);
  };

  const handleCloseAircraft = () => {
    // Call the onClose prop to close the modal
    onClose();
  };
  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <Box sx={{ ...style, display: "flex", justifyContent: "center" }}>
          <form onSubmit={onSubmitForm}>
            <div className="clear_icon">
              <ClearIcon style={{marginTop:"2%"}} onClick={handleCloseAircraft} />
            </div>
            <div>
              <h2 className="heading2">Add New Aircraft</h2>
              <p className="heading4 secondary" style={{ fontWeight: "600" }}>
                Aircraft Details
              </p>
              <label className="heading5" style={{ marginBottom: "5px" }}>
                Registeration number
              </label>
              <div className="add_group">
                <input
                //set here 
                  type="text"
                  placeholder="UTF3235"
                  className="add_input"
                  id="registeration_no"
                  onChange={(e) => handleReg(e)}
                />
              </div>
              <div
                className="f-flex"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <label className="heading5" style={{ marginBottom: "5px" }}>
                  Aircraft ID
                </label>
                <label
                  className="heading5"
                  style={{ marginBottom: "5px", marginRight: "40%" }}
                >
                  Aircraft Type
                </label>
              </div>
              <div
                className="f-flex"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="add_group">
                  <input
                    type="text"
                    value={aircarftid}
                    placeholder="UTF3235"
                    className="add_input"
                  />
                </div>
                <div className="add_group" style={{ marginRight: "20%" }}>
                  <input
                    value={aircrafttype}
                    type="text"
                    placeholder="ASEL"
                    className="add_input"
                  />
                </div>
              </div>
              <div
                className="f-flex"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="add_span">
                  <input
                    type="checkbox"
                    className="add_checkbox"
                    id="defaultValue"
                    name="defualt"
                    onChange={handleDefaut}
                    checked={defaultValue}
                  />
                  <label className="heading4">Default</label>
                </div>
                <div className="add_span" style={{ marginRight: "40%" }}>
                  <input
                    type="checkbox"
                    className="add_checkbox"
                    id="favorite"
                    name="favorite"
                    onChange={handleFavorite}
                    checked={favorite}
                  />
                  <label className="heading4">Favorite</label>
                </div>
              </div>
              <div
                className="f-flex"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <label className="heading5" style={{ marginBottom: "5px" }}>
                  Default Crew Position
                </label>
                <label
                  className="heading5"
                  style={{ marginBottom: "5px", marginRight: "32%" }}
                >
                  Type of Operations
                </label>
              </div>
              <div
                className="f-flex"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="add_group">
                  <input
                    type="text"
                    placeholder="Select"
                    className="add_input"
                  />
                </div>
                <div className="add_group" style={{ marginRight: "20%" }}>
                  <input
                    type="text"
                    placeholder="Select"
                    className="add_input"
                  />
                </div>
              </div>
              <div
                className="f-flex"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <label className="heading5" style={{ marginBottom: "5px" }}>
                  Aircraft Body Type
                </label>
                <label
                  className="heading5"
                  style={{ marginBottom: "5px", marginRight: "40%" }}
                >
                  Engine Type
                </label>
              </div>
              <div className="f-flex">
                <div className="add_group">
                  <input
                    value={bodytype}
                    type="text"
                    placeholder="Select"
                    className="add_input"
                  />
                </div>
                <div className="add_group">
                  <input
                    value={enginetype}
                    type="text"
                    placeholder="Select"
                    className="add_input"
                  />
                </div>
              </div>
              {/* <FormControl fullWidth className="add_group">
                      <InputLabel id="demo-simple-select-label">Age</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Age"
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl> */}
              <div style={{ display: "flex" }}>
                <div className="add_span" style={{ flexGrow: "1" }}>
                  <input
                    type="checkbox"
                    className="add_checkbox"
                    id="complex"
                    name="complex"
                  />
                  <label className="heading4">Complex</label>
                </div>
                <div className="add_span" style={{ flexGrow: "1", marginRight:"10%" }}>
                  <input
                    type="checkbox"
                    className="add_checkbox"
                    id="pressurized"
                    name="pressurized"
                  />
                  <label className="heading4">Pressurized</label>
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div className="add_span" style={{ flexGrow: "1" }}>
                  <input
                    type="checkbox"
                    className="add_checkbox"
                    id="retractable"
                    name="retractable"
                  />
                  <label className="heading4">Retractable</label>
                </div>
                <div className="add_span" style={{ flexGrow: "1",marginRight:"5%" }}>
                  <input
                    type="checkbox"
                    className="add_checkbox"
                    id="default"
                    name="defualt"
                  />
                  <label className="heading4">High Performance</label>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="add_span" style={{ flexGrow: "1" }}>
                  <input
                    type="checkbox"
                    className="add_checkbox"
                    id="default"
                    name="defualt"
                  />
                  <label className="heading4">Amphibian</label>
                </div>
                <div className="add_span" style={{ flexGrow: "1" ,marginRight:"24%" }}>
                  <input
                    type="checkbox"
                    className="add_checkbox"
                    id="default"
                    name="defualt"
                  />
                  <label className="heading4">Skis</label>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="add_span" style={{ flexGrow: "2" }}>
                  <input
                    type="checkbox"
                    className="add_checkbox"
                    id="default"
                    name="defualt"
                  />
                  <label className="heading4">EFIS</label>
                </div>
                <div className="add_span" style={{ flexGrow: "2"  }}>
                  <input
                    type="checkbox"
                    className="add_checkbox"
                    id="default"
                    name="defualt"
                  />
                  <label className="heading4">Fuel Injection</label>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="add_span" style={{ flexGrow: "2" }}>
                  <input
                    type="checkbox"
                    className="add_checkbox"
                    id="default"
                    name="defualt"
                  />
                  <label className="heading4">Tailwheel</label>
                </div>
                <div className="add_span" style={{ flexGrow: "2" ,marginRight:"13%"}}>
                  <input
                    type="checkbox"
                    className="add_checkbox"
                    id="default"
                    name="defualt"
                  />
                  <label className="heading4">Aerobatic</label>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="add_span" style={{ flexGrow: "2" }}>
                  <input
                    type="checkbox"
                    className="add_checkbox"
                    id="default"
                    name="defualt"
                  />
                  <label className="heading4">Experimental</label>
                </div>
                <div className="add_span" style={{ flexGrow: "2", marginRight:"21%" }}>
                  <input
                    type="checkbox"
                    className="add_checkbox"
                    id="default"
                    name="defualt"
                  />
                  <label className="heading4">Warbird</label>
                </div>
              </div>
              <div className="add_span" style={{ flexGrow: "2" }}>
                <label
                  className="heading4 secondary"
                  style={{ fontWeight: "600" }}
                >
                  Autolog
                </label>
              </div>
              <div className="add_span" style={{ flexGrow: "2" }}>
                <label className="heading4 secondary" style={{ fontWeight: "600" }}>
                  Custom Fields
                </label>
               
              </div>
              <div style={{ background: "#F8FAFB", display:"flex", justifyContent:"space-between", padding:"3%", borderRadius:"10px", marginBottom:"5%" }}>
                    <span>
                    Custom Field 
                    </span>
                    <div>
                  <input
                style={{
                  padding: '3%',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  fontSize: '14px',
                  width: '100%',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
                }}
                    type="text"
                    id="textInput" 
                    placeholder="Enter text..."  />
                    </div>
                  <DeleteOutlineSharpIcon style={{color:"red"}}/>
                </div>

              <button className="add_btn" style={{ float: 'right', marginRight:"2%" }}>
                <span>Add Aircraft</span> 
              </button>
              <br />
              <br />
              <br />
              <br />
              <br />
              
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

export default AddAircraftModal;
