
function AircraftModels() {
  return (
    <div className="dashboard">
      <section
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="dashboard_heading">Aircraft Models</h4>
        <h6 className="dashboard_subheading">
          Dashboard {">"} Aircraft Models
        </h6>
    </section>
    </div>
   );}

   export default AircraftModels