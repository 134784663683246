import { Link } from "react-router-dom";
import "./Sidebar.css";
import dashboard from "../../assets/images/Dashboard.svg";
import search from "../../assets/images/Search.svg";
import fligths from "../../assets/images/Flights.svg";
import aircraft from "../../assets/images/Aircraft.svg";
import airport from "../../assets/images/Airport.svg";
import total from "../../assets/images/Total.svg";
import imports from "../../assets/images/Import.svg";
import chevron from "../../assets/images/chevron.svg";
import React from "react";

import AddFlightModal from "../Modal/AddFlightModal";

import AddAircraftModal from "../Modal/AddAircraftModal";
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';

function Sidebar() {
  const [showNestedList, setShowNestedList] = React.useState(false);
  const [aircraftNestedList, setAircraftNestedList] = React.useState(false);
  const [addFlight, setAddFlight] = React.useState(false);
  const [addAircraft, setAddAircraft] = React.useState(false);

  const handleAddFlight = () => {
    setAddFlight(true);
  };

  const handleCloseFlight = () => {
    setAddFlight(false);
  };

  const handleAddAircraft = () => {
    setAddAircraft(true);
  };

  const handleCloseAircraft = () => {
    setAddAircraft(false);
  };

  const handleToggleAircraftList = () => {
    setAircraftNestedList(!aircraftNestedList);
  };

  const handleToggleNestedList = () => {
    setShowNestedList(!showNestedList);
  };

  // const [age, setAge] = React.useState('');

  // const handleChange = (event: SelectChangeEvent) => {
  //   setAge(event.target.value as string);
  // };

  return (
    <>
      <div style={{ background: " #08192B" }} className="side_menu">
        <h4 className="heading5 logbook_heading">
          LOGBOOK <img src={chevron} alt="Chevron Icon" />
        </h4>
        <ul className="main_menu">
          <li style={{ display: "flex", gap: "10px" }}>
            <img src={dashboard} alt="Dashboard Logo" />
            <Link to="/">Dashboard</Link>
          </li>
          <li style={{ display: "flex", gap: "10px" }}>
            <img src={search} alt="Search Logo" />
            <a>Search</a>
          </li>
          <li
            onClick={handleToggleNestedList}
            style={{ display: "flex", gap: "10px" }}
          >
            <img src={fligths} alt="Flights Logo" />
            <span>Flights</span>
          </li>
          {showNestedList && (
            <ul className="main_menu">
              <li>
                <Link to="/logbook/flights">All Flights</Link>
              </li>
              <li onClick={handleAddFlight}>
                <span>Add New Flight</span>
              </li>

              {/* Add New Flight Form  */}
              <AddFlightModal open={addFlight} onClose={handleCloseFlight} />
              {/*Ends here*/}
            </ul>
          )}
          <li
            onClick={handleToggleAircraftList}
            style={{ display: "flex", gap: "10px" }}
          >
            <img src={aircraft} alt="Aircraft Logo" />
            <span>Aircraft</span>
          </li>
          {aircraftNestedList && (
            <ul className="main_menu">
              <li>
                <Link to="/logbook/aircrafts">All Aircrafts</Link>
              </li>
              <li>
                <Link to="/logbook/aircrafts/aircraft_models">
                  Aircraft Models
                </Link>
              </li>
              <li onClick={handleAddAircraft}>
                <span>Add New Aircraft</span>
              </li>

              {/* Add New AirCraft Form  */}
              <AddAircraftModal open={addAircraft} onClose={handleCloseAircraft} />
              {/* Form ends here */}

              <li>
                <a>Flying Club</a>
              </li>
              <li>
                <a>Import Aircraft</a>
              </li>
            </ul>
          )}
          <li style={{ display: "flex", gap: "10px" }}>
            <img src={airport} alt="Airport Logo" />
            <a>Airports</a>
          </li>
          <li style={{ display: "flex", gap: "10px" }}>
            <img src={total} alt="Totals Logo" />
            <a>Totals</a>
          </li>
          <li style={{ display: "flex", gap: "10px" }}>
            <img src={imports} alt="Imports Logo" />
            <a>Import</a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
