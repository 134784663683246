import "./Aircrafts.css";
import React from "react";
import {RiArrowDropUpLine} from 'react-icons/ri'
import { BsPlusLg} from "react-icons/bs";
import aircraft_image from "../../../assets/images/aircraft_img.svg"
import img_aircraft from "../../../assets/images/img_aircraft.svg"
import {FaPlane} from "react-icons/fa"
function Aircrafts() {
    const imageCardData = [
        {
          id: 1,
          imageSrc: aircraft_image,
          heading: 'SIMAATDCRX',
          subheading: 'AATD CRX MAX - AA (ASEL)',
          night: '02 Flights',
          day: '02 Flights',
          desc:"Lorem ispum dolor site craft amit ispum dolor site amit.",
        },
        {
          id: 2,
          imageSrc: img_aircraft,
          heading: 'SIMAATDCRX',
          subheading: 'AATD CRX MAX - AA (ASEL)',
          night: '02 Flights',
          day: '02 Flights',
          desc:"Lorem ispum dolor site craft amit ispum dolor site amit.",
        },
        {
            id: 3,
            imageSrc: img_aircraft,
            heading: 'SIMAATDCRX',
            subheading: 'AATD CRX MAX - AA (ASEL)',
            night: '02 Flights',
          day: '02 Flights',
          desc:"Lorem ispum dolor site craft amit ispum dolor site amit.",
          },

          {
            id: 4,
            imageSrc: img_aircraft,
            heading: 'SIMAATDCRX',
            subheading: 'AATD CRX MAX - AA (ASEL)',
            night: '02 Flights',
          day: '02 Flights',
          desc:"Lorem ispum dolor site craft amit ispum dolor site amit.",
          },
    ]
  return (
    <div className="dashboard">
      <section style={{ display: "flex",alignItems:"center",justifyContent:"space-between" }}>
        <h4 className="dashboard_heading">My Aircrafts</h4>
        <h6 className="dashboard_subheading">Dashboard {">"} Aircrafts</h6>
      </section>
      <section style={{ display: "flex",alignItems:"center",justifyContent:"space-between" }}>
      <div style={{ display: "flex",gap:"15px"}}>
      <button className="filter_btn">Filter <RiArrowDropUpLine/></button>
      <button className="filter_btn">Filter <RiArrowDropUpLine/></button>
      </div>
      <button className="add_flight_btn"><BsPlusLg/> Add Aircraft</button>
      </section> 
      <div className="image-card-container">
      {imageCardData.map((data) => (
        <div className="image-card-container"   key={data.id}>
         <div className="image-card" >
         <img src={data.imageSrc} alt="Card" className="card-image" />
         <div className="card-content">
           <h2 className="heading3" style={{fontWeight:"600",paddingLeft:"5%"}}>{data.heading}</h2>
           <p className="heading5 text_color2" style={{paddingLeft:"5%"}}>{data.subheading}</p>
           <div style={{display:"flex"}}>
           <p className="heading5 text_color2" style={{paddingLeft:"5%"}}><FaPlane className="secondary"/>  {data.night}</p>
           <p className="heading5 text_color2" style={{paddingLeft:"5%"}}><FaPlane  className="secondary"/>  {data.day}</p>
           </div>
           <p  className="heading5 text_color2" style={{paddingLeft:"5%"}}>{data.desc}</p>
         </div>
       </div>
       </div>
      ))}
    </div>
    </div>
  );
}

export default Aircrafts;
