import './Register.css'
import header from '../../assets/images/logo_one.svg'
import facebookIcon from '../../assets/images/Facebook.svg'
import linkedinIcon from '../../assets/images/Linkedin.svg'
import twitterIcon from '../../assets/images/Twitter.svg'
import gmailIcon from '../../assets/images/Gmail.svg'
// import footerImage from '../../assets/images/footer_img.svg'
// import backgroundImage from '../../assets/images/Login.svg'
function Register() {
    return (
      <div >
       <img src={header} className='register_logo' alt='Pilot Book'/>
       <form>
       <h2 className='welcome heading2'>Register</h2>
       <h4 className='register_subheading heading4'>Create your free account at Pilots Logbook</h4>
       <label className='r_fname_label heading4'>First Name</label>
        <input
         type="text"
        placeholder="John"
        className='r_fname_input'
        required
        />
        <label className='r_lname_label heading4'>Last Name</label>
        <input
         type="text"
        placeholder="Smith"
        className='r_lname_input'
        required
        />
     <label className='r_email_label heading4'>Email</label>
        <input
         type="text"
        placeholder="Enter Email"
        className='r_email_input'
        required
        />
         <label className='r_pass_label heading4'>Password</label>
        <input
         type="text"
        placeholder="Enter Password"
        className='r_pass_input'
        required
        />
      <button className='register_btn'>
        Register
      </button>
        </form>
        <h4 className='register_with heading4'>Sign up using</h4>
        <div className='r_social_icons'>
          <img src={facebookIcon} className='r_facebook' alt='Facebook Icon'/>
          <img src={linkedinIcon} className='r_linkedin' alt='LinkedIn Icon'/>
          <img src={twitterIcon} className='r_twitter' alt='Twitter Icon'/>
          <img src={gmailIcon} className='r_gmail' alt='Gmail Icon'/>
        </div>
        <h4 className='login_now heading4'>Already have an account ?<a href='/'><span style={{textDecoration:"underline",color:"#232629"}}>Login</span></a></h4>
        <h5 className='term_of_use heading4'>By registering you agree to the our Terms of Use</h5> 
        {/* <img src={footerImage} className='footer_img' alt="LandScape" /> */}
        <h4 className='r_copyright heading4'>© 2023 Pilots Logbook</h4>
        {/* <img src={backgroundImage} className='login_bg' alt='Login Background'/>
        <h2 className='login_bg_heading'>The most complete digital pilot logbook</h2> */}
      </div>
    );
  }
  
  export default Register;
  